html, body {
    background: rgba(204, 204, 204, 0.45);
    min-height: 100vh;
}

#root, .App {
    padding: 20px 20px 0;
}

@media print {
    #root, .App {
        padding: 0;
    }
    html, body{
        background: #FFF;
    }

}

@media only screen and (max-width: 768px) {
    h4 {
        font-size: 1.5em;
    }

    #root, .App {
        padding: 0;
        font-size: 1.2em;
    }

}
